<template>
  <el-dialog
    :title="$t('my_setPayPassword_1')"
    :visible.sync="showMask"
    width="500px"
  >
    <div class="setPassword">
      <div class="limit-tit">{{ $t('my_setPayPassword_2') }}：</div>
      <div class="limit-ls">1.{{ $t('my_setPayPassword_3') }}（A-Z）</div>
      <div class="limit-ls">2.{{ $t('my_setPayPassword_4') }}（a-z）</div>
      <div class="limit-ls">3.{{ $t('my_setPayPassword_5') }}（0-9）</div>
      <div class="limit-ls">
        4.{{ $t('my_setPayPassword_6') }} (~!@#$%^&*()_+`\-={}:";'&lt;>,.)
      </div>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label-width="120px"
          :label="$t('my_setPayPassword_7')"
          prop="new_password"
        >
          <el-input
            type="password"
            v-model="ruleForm.new_password"
            :placeholder="$t('my_setPayPassword_8')"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          label-width="120px"
          :label="$t('my_setPayPassword_9')"
          prop="re_new_password"
        >
          <el-input
            type="password"
            v-model="ruleForm.re_new_password"
            :placeholder="$t('my_setPayPassword_10')"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="showMask = false">
        {{ $t('my_setPayPassword_11') }}
      </el-button>
      <el-button
        :loading="nextLoading"
        size="small"
        type="primary"
        @click="showCheckPayPassword"
      >
        {{ $t('my_setPayPassword_12') }}
      </el-button>
    </span>
    <emailVerify
      @checkPayPassword="checkPayPassword"
      type="moneypwd"
      :isshow.sync="isEmailVerify"
    ></emailVerify>
  </el-dialog>
</template>

<script>
import emailVerify from '@/components/pay/emailVerify.vue'
export default {
  components: {
    emailVerify,
  },
  props: {
    isshow: {
      type: Boolean,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
      if (val === true) {
        this.$nextTick(() => {
          this.$refs.ruleForm.resetFields()
        })
      }
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
  },
  data() {
    let that = this
    let validatePass = (rule, value, callback) => {
      let reg =
        /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[~!@#$%^&*()_+`\-={}:";'<>,.])(?=.*[~!@#$%^&*()_+`\-={}:";'<>,.]))[a-z\d~!@#$%^&*()_+`\-={}:";'<>,.]{8,}/i
      if (value === '') {
        callback(new Error(that.$t('my_setPayPassword_13')))
      } else if (!reg.test(value)) {
        callback(new Error(that.$t('my_setPayPassword_14')))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(that.$t('my_setPayPassword_15')))
      } else if (value !== this.ruleForm.new_password) {
        callback(new Error(that.$t('my_setPayPassword_16')))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        new_password: '',
        re_new_password: '',
      },
      rules: {
        new_password: [{ validator: validatePass, trigger: 'blur' }],
        re_new_password: [{ validator: validatePass2, trigger: 'blur' }],
      },
      showMask: false,
      nextLoading: false,
      isEmailVerify: false,
      checkPayPasswordRes: 0,
    }
  },
  methods: {
    checkPayPassword(checkPayPassword) {
      this.checkPayPasswordRes = checkPayPassword
    },
    showCheckPayPassword() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.checkPayPasswordRes == 1) {
            this.savePass()
          } else {
            this.isEmailVerify = true
          }
        }
      })
    },
    async savePass() {
      this.nextLoading = true
      let res = await this.$axios.post('/api/User/updMoneyPwd', this.ruleForm)
      this.nextLoading = false
      if (res.code === 1) {
        this.$message({
          message: res.msg,
          type: 'success',
        })
        this.showMask = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.limit-tit {
  font-size: 14px;
  color: #333333;
  margin-bottom: 10px;
}
.limit-ls {
  margin-bottom: 10px;
}
.demo-ruleForm {
  margin-top: 30px;
  width: 400px;
}
.textCenter {
  margin-top: 50px;
  .el-button {
    width: 100%;
    margin-left: 0;
  }
  .tip {
    color: #409eff;
    margin-top: 20px;
    cursor: pointer;
  }
}
</style>
