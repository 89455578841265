var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"append-to-body":true,"title":_vm.$t('安全验证'),"visible":_vm.showMask,"width":"500px"},on:{"update:visible":function($event){_vm.showMask=$event}}},[_c('div',{staticClass:"setPassword"},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"hide-required-asterisk":true,"status-icon":"","size":"small","label-width":"100px"}},[_c('el-form-item',{attrs:{"label":_vm.$t('支付密码：'),"rules":[
            {
              required: true,
              message: _vm.$t('请输入支付密码'),
              trigger: 'blur',
            } ],"prop":"money_password"}},[_c('el-input',{attrs:{"type":"password","show-password":"","placeholder":_vm.$t('请输入支付密码'),"autocomplete":"off"},model:{value:(_vm.ruleForm.money_password),callback:function ($$v) {_vm.$set(_vm.ruleForm, "money_password", $$v)},expression:"ruleForm.money_password"}})],1),(this.googleVerity.is_google_verity == 1)?_c('el-form-item',{attrs:{"label":_vm.$t('Google验证：'),"rules":[
            {
              required: true,
              message: _vm.$t('请输入Google验证码'),
              trigger: 'blur',
            } ],"prop":"google"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('请输入Google验证码'),"autocomplete":"off"},model:{value:(_vm.ruleForm.google),callback:function ($$v) {_vm.$set(_vm.ruleForm, "google", $$v)},expression:"ruleForm.google"}},[(this.googleVerity.is_google_bind == 0)?_c('template',{slot:"suffix"},[_c('span',{staticClass:"suffix-text",class:_vm.disEmail,on:{"click":_vm.sendBinding}},[_vm._v(" "+_vm._s(_vm.$t('去绑定'))+" ")])]):_vm._e()],2)],1):_vm._e(),_c('el-form-item',{attrs:{"label":_vm.$t('邮箱验证：'),"rules":[
            {
              required: true,
              message: _vm.$t('请输入邮箱验证码'),
              trigger: 'blur',
            } ],"prop":"code"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('请输入邮箱验证码'),"autocomplete":"off"},model:{value:(_vm.ruleForm.code),callback:function ($$v) {_vm.$set(_vm.ruleForm, "code", $$v)},expression:"ruleForm.code"}},[_c('template',{slot:"suffix"},[_c('span',{staticClass:"suffix-text",class:_vm.disEmail ? 'dis' : '',on:{"click":_vm.sendEmail}},[_vm._v(" "+_vm._s(_vm.sendEmailText)+" ")])])],2)],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"loading":_vm.nextLoading,"size":"small","type":"primary"},on:{"click":_vm.savePass}},[_vm._v(" "+_vm._s(_vm.$t('确认'))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }