<template>
  <div>
    <el-dialog
      :title="$t('my_addWalletAddr_1')"
      :visible.sync="showMask"
      width="500px"
    >
      <div class="setPassword">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item :label="$t('my_addWalletAddr_2')" prop="currency_type">
            <el-select
              v-model="ruleForm.currency_type"
              :placeholder="$t('my_addWalletAddr_3')"
              @change="curiencyChange"
            >
              <el-option
                v-for="(item, index) in currencyOptions"
                :key="index"
                :value="item"
                :label="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('请选择协议')" prop="agreement">
            <el-select
              v-model="ruleForm.agreement"
              :placeholder="$t('请选择协议')"
            >
              <el-option
                v-for="(item, index) in agreementList"
                :key="index"
                :value="item"
                :label="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('my_addWalletAddr_4')" prop="chain_addr">
            <el-input
              type="text"
              v-model="ruleForm.chain_addr"
              :placeholder="$t('my_addWalletAddr_5')"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="" class="default">
            <el-checkbox v-model="defaults">
              {{ $t('设为默认钱包') }}
            </el-checkbox>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="showMask = false">
          {{ $t('my_addWalletAddr_6') }}
        </el-button>
        <el-button
          :loading="nextLoading"
          size="small"
          type="primary"
          @click="showCheckPayPassword"
        >
          {{ $t('my_addWalletAddr_7') }}
        </el-button>
      </span>
    </el-dialog>
    <check-pay-password-dialog
      @checkPayPassword="checkPayPassword"
      :isshow.sync="isCheckPayPasswordDialog"
    ></check-pay-password-dialog>
  </div>
</template>

<script>
import checkPayPasswordDialog from '@/components/pay/checkPayPasswordDialog.vue'

export default {
  name: 'addWalletAddrDialog',
  components: {
    checkPayPasswordDialog,
  },
  props: {
    isshow: {
      type: Boolean,
    },
    currencyOptions: {
      type: Array,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
  },
  data() {
    return {
      isCheckPayPasswordDialog: false,
      ruleForm: {
        id: 0,
        currency_type: '',
        chain_addr: '',
      },
      rules: {
        currency_type: [
          {
            required: true,
            message: this.$t('my_addWalletAddr_8'),
            trigger: 'change',
          },
        ],
        agreement: [
          {
            required: true,
            message: this.$t('请选择协议'),
            trigger: 'change',
          },
        ],
        chain_addr: [
          {
            required: true,
            message: this.$t('my_addWalletAddr_9'),
            trigger: 'blur',
          },
        ],
      },
      defaults: false,
      agreementList: [],
      showMask: false,
      nextLoading: false,
      checkPayPasswordRes: 0,
    }
  },
  mounted() {},
  methods: {
    checkPayPassword(checkPayPassword) {
      this.checkPayPasswordRes = checkPayPassword
    },
    showCheckPayPassword() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.checkPayPasswordRes == 1) {
            this.savePass()
          } else {
            this.isCheckPayPasswordDialog = true
          }
        }
      })
    },
    initData(item) {
      this.defaults = false
      if (item) {
        this.ruleForm = item
        if (item.is_default === 1) {
          this.defaults = true
        }
      } else {
        this.ruleForm = {
          id: '',
          currency_type: '',
          chain_addr: '',
          is_default: 0,
        }
      }
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
    async curiencyChange(val) {
      let res = await this.$axios.post('/api/Common/getAgreement', {
        currency: val,
      })
      if (res.code === 1) {
        this.agreementList = res.data
      }
    },
    savePass() {
      if (this.defaults) {
        this.ruleForm.is_default = 1
      } else {
        this.ruleForm.is_default = 0
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.nextLoading = true
          let res = await this.$axios.post('/api/Info/addWallet', this.ruleForm)
          this.nextLoading = false
          if (res.code === 1) {
            this.showMask = false
            this.$emit('updateWalletAddrList')
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 90%;
}
</style>
