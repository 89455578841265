<template>
  <el-dialog
    :title="$t('my_setPassportCode_1')"
    :visible.sync="showMask"
    width="500px"
  >
    <div class="setPassword">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('my_setPassportCode_2')" prop="passportcode">
          <el-input
            type="text"
            v-model="ruleForm.passportcode"
            :placeholder="$t('my_setPassportCode_3')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="showMask = false">
        {{ $t('my_setPassportCode_4') }}
      </el-button>
      <el-button
        :loading="nextLoading"
        size="small"
        type="primary"
        @click="savePass"
      >
        {{ $t('my_setPassportCode_5') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'setPassportCodeDialog',
  props: {
    passportcode: {
      type: String,
    },
    isshow: {
      type: Boolean,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
    passportcode(val) {
      this.ruleForm.passportcode = val
    },
  },
  data() {
    let that = this
    let valipassportcode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(that.$t('my_setPassportCode_3')))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        passportcode: '',
      },
      rules: {
        passportcode: [{ validator: valipassportcode, trigger: 'blur' }],
      },
      showMask: false,
      nextLoading: false,
    }
  },
  mounted() {},
  methods: {
    savePass() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.nextLoading = true
          let res = await this.$axios.post(
            '/api/User/updPassCode',
            this.ruleForm
          )
          this.nextLoading = false
          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.showMask = false
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
