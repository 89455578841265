<template>
  <el-dialog :title="$t('my_record_1')" :visible.sync="showMask" width="700px">
    <div class="record">
      <el-form
        size="small"
        :inline="true"
        :model="searchData"
        label-width="44px"
      >
        <el-form-item :label="$t('my_record_5')">
          <el-select
            v-model="searchData.status"
            @change="getApplyRecord"
            clearable
            :placeholder="$t('请选择')"
          >
            <el-option :label="$t('my_record_6')" :value="1" />
            <el-option :label="$t('my_record_7')" :value="2" />
            <el-option :label="$t('my_record_8')" :value="3" />
          </el-select>
        </el-form-item>
      </el-form>
      <el-table class="wallet-table" :data="tableList" border size="small">
        <el-table-column
          prop="createtime"
          :label="$t('my_record_2')"
        ></el-table-column>
        <el-table-column
          prop="plan_num"
          :label="$t('my_record_3')"
        ></el-table-column>
        <el-table-column
          prop="apply_fee"
          :label="$t('my_record_4')"
        ></el-table-column>
        <el-table-column :label="$t('my_record_5')">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">{{ $t('my_record_6') }}</span>
            <span v-if="scope.row.status == 2">{{ $t('my_record_7') }}</span>
            <span v-if="scope.row.status == 3">{{ $t('my_record_8') }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('驳回原因')">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              @click="openReject(scope.row.content)"
              v-if="scope.row.status == 3"
              >{{ $t('查看详情') }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="searchData.current"
          :page-size="searchData.size"
          background
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" @click="showMask = false">
        {{ $t('my_record_9') }}
      </el-button>
    </span>
    <el-dialog
      :append-to-body="true"
      :title="$t('驳回原因')"
      :visible.sync="showReject"
      width="700px"
    >
      <div class="record">
        {{ reject }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="showReject = false">
          {{ $t('my_record_9') }}
        </el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
export default {
  name: 'addBankCardDialog',
  props: {
    isshow: {
      type: Boolean,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
  },
  data() {
    return {
      tableList: [],
      total: 0,
      searchData: {
        current: 1,
        size: 10,
        status: '',
      },
      showMask: false,
      showReject: false,
      reject: '',
    }
  },
  created() {
    this.searchData.current = 1
    this.getApplyRecord()
  },
  methods: {
    //获取申请记录列表
    async getApplyRecord() {
      this.tableLoading = true
      let res = await this.$axios.post(
        '/api/Exchange/applyRecord',
        this.searchData
      )
      this.tableLoading = false
      if (res.code === 1) {
        this.total = res.data.total
        this.tableList = res.data.list
      }
    },
    handleCurrentChange(val) {
      this.searchData.current = val
      this.getApplyRecord()
    },
    openReject(item) {
      this.reject = item
      this.showReject = true
    },
  },
}
</script>

<style scoped lang="scss"></style>
