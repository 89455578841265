<template>
  <div>
    <el-dialog
      :title="$t('my_applyRealCard_1')"
      :visible.sync="showMask"
      width="500px"
    >
      <div class="setPassword">
        <div class="card-item">
          <span class="card-label">{{ $t('my_applyRealCard_2') }}</span
          ><span class="card-con">{{ bankCard.nickname_zh }}</span>
        </div>
        <div class="card-item">
          <span class="card-label">{{ $t('my_applyRealCard_3') }}</span
          ><span class="card-con">{{ bankCard.nickname_en }}</span>
        </div>
        <div class="card-item">
          <span class="card-label">{{ $t('my_applyRealCard_4') }}</span
          ><span class="card-con">{{ bankCard.bank_number }}</span>
        </div>
        <div class="card-item">
          <span class="card-label">{{ $t('my_applyRealCard_5') }}</span
          ><span class="card-con">{{ bankCard.bank_name }}</span>
        </div>
        <div class="card-item">
          <span class="card-label">{{ $t('my_applyRealCard_6') }}</span
          ><span class="card-con">{{ bankCard.bank_name_en }}</span>
        </div>
        <div class="card-item">
          <span class="card-label">{{ $t('my_applyRealCard_7') }}</span>
          <span class="user-address">{{ user_address.username }}</span>
          <span class="user-address"> ({{ user_address.phone }})</span>
          <span class="user-address-edit" @click="handelSelectAddr"
            ><i class="el-icon-edit-outline"></i
          ></span>
        </div>
        <div class="card-item">
          <span class="card-label"> </span>
          <span class="user-address">{{ user_address.city }}</span>
          <span class="user-address">{{ user_address.country }}</span>
          <span class="user-address">{{ user_address.addr_desc }}</span>
        </div>
        <div class="card-item">
          <span class="card-label">{{ $t('my_applyRealCard_8') }}</span
          ><span class="card-con card-fee">{{ work_money }}</span>
        </div>
        <div class="card-item">
          <span class="card-label">{{ $t('my_applyRealCard_9') }}</span
          ><span class="card-con card-fee">{{ code_money }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="showMask = false">
          {{ $t('my_applyRealCard_10') }}
        </el-button>
        <el-button
          :loading="nextLoading"
          size="small"
          type="primary"
          @click="savePass"
        >
          {{ $t('my_applyRealCard_11') }}
        </el-button>
      </span>
    </el-dialog>
    <select-addr-dialog
      :isshow.sync="isSelectAddrDialog"
      @updateData="updateAddr"
    ></select-addr-dialog>
    <payment
      :isshow.sync="isPayment"
      :payData="payData"
      @updateData="updateData"
    ></payment>
  </div>
</template>
<script>
import selectAddrDialog from './selectAddrDialog.vue'
import payment from '@/components/pay/usdPayment.vue'
export default {
  components: {
    selectAddrDialog,
    payment,
  },
  name: 'addBankCardDialog',
  props: {
    isshow: {
      type: Boolean,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
  },
  data() {
    return {
      isSelectAddrDialog: false,
      bankCard: {},
      code_money: 0,
      work_money: 0,
      user_address: {},
      ruleForm: {
        card_id: 0,
        addr_id: 0,
      },
      rules: {
        con_phone: [{ require: true, trigger: 'blur' }],
        code: [{ require: true, trigger: 'blur' }],
      },
      isPayment: false,
      showMask: false,
      nextLoading: false,
      payData: {
        type: 2,
        bank_id: '',
      },
    }
  },
  created() {
    this.getApplyConf()
  },
  methods: {
    updateAddr(item) {
      this.user_address = item
      if (this.user_address.length != 0) {
        this.ruleForm.addr_id = this.user_address.id
      }
    },
    setBankCardData(data) {
      this.bankCard = data
      this.ruleForm.card_id = data.id
    },
    //获取邮费 制作费
    async getApplyConf() {
      let res = await this.$axios.post('/api/Info/realCard')
      if (res.code === 1) {
        this.code_money = res.data.code_money
        this.work_money = res.data.work_money
        this.user_address = res.data.user_address
        if (this.user_address.length != 0) {
          this.ruleForm.addr_id = this.user_address.id
        }
      }
    },
    handelSelectAddr() {
      this.isSelectAddrDialog = true
    },
    updateData() {
      this.showMask = false
      this.$emit('updateData')
    },
    //申请
    async savePass() {
      this.nextLoading = true
      let res = await this.$axios.post('/api/Info/cardOrder', {
        bank_id: this.ruleForm.card_id,
      })
      this.nextLoading = false
      if (res.code === 1) {
        this.payData = {
          ...this.payData,
          ...res.data,
          bank_id: this.ruleForm.card_id,
        }
        this.isPayment = true
      }
    },
  },
}
</script>

<style scoped lang="scss">
.card-item {
  line-height: 26px;
}

.card-label {
  width: 170px;
  display: inline-block;
  text-align: right;
  font-size: 16px;
}

.card-con {
  font-size: 12px;
  font-weight: bold;
}

.user-address {
  font-size: 12px;
}

.user-address-edit {
  float: right;
  cursor: pointer;
}

.card-fee {
  font-size: 20px;
  color: #cf5358;
}
</style>
