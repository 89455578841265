<template>
  <div>
    <el-dialog
      :title="$t('my_selectAddr_1')"
      :visible.sync="showMask"
      width="800px"
    >
      <div class="setPassword flex-start">
        <el-card
          class="box-card"
          v-for="(item, index) in addressList"
          :key="index"
        >
          <div @click="cardAddrSet(item)">
            <div>
              <span class="name">{{ item.username }}</span>
              <span>{{ item.phone }}</span>
              <i
                @click.stop="delAddr(item)"
                class="card-delete el-icon-delete"
              ></i>
              <el-tag
                class="default-tag"
                size="mini"
                v-if="item.is_default == 1"
                >{{ $t('my_selectAddr_2') }}</el-tag
              >
            </div>
            <div class="addr">
              <span>{{ item.city }}</span>
              <span>{{ item.country }}</span>
              <span>{{ item.addr_desc }}</span>
              <i @click.stop="editAddr(item)" class="edit el-icon-edit"></i>
            </div>
          </div>
        </el-card>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          :loading="nextLoading"
          size="small"
          type="primary"
          @click="handleAddAddr"
        >
          {{ $t('my_selectAddr_3') }}
        </el-button>
      </span>
    </el-dialog>
    <add-addr-dialog
      ref="addrDialog"
      :isshow.sync="isAddAddrDialog"
      @updateData="getAddressList"
    ></add-addr-dialog>
  </div>
</template>

<script>
import addAddrDialog from './addAddrDialog.vue'

export default {
  components: {
    addAddrDialog,
  },
  name: 'addBankCardDialog',
  props: {
    isshow: {
      type: Boolean,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
  },
  data() {
    return {
      isAddAddrDialog: false,
      addressList: {}, //收货地址列表
      ruleForm: {
        nickname_zh: '',
      },
      showMask: false,
      nextLoading: false,
    }
  },
  created() {
    this.getAddressList()
  },
  methods: {
    editAddr(item) {
      this.$refs.addrDialog.initData(item)
    },
    delAddr(item) {
      let _that = this
      this.$confirm(_that.$t('确认要删除该地址吗？'), _that.$t('提示'), {
        confirmButtonText: _that.$t('确认'),
        cancelButtonText: _that.$t('取消'),
        type: 'warning',
      })
        .then(async () => {
          let res = await this.$axios.post('/api/Info/delUserAddress', {
            id: item.id,
          })
          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.getAddressList()
          }
        })
        .catch(() => {})
    },
    cardAddrSet(item) {
      if (this.type == 2) return
      this.$emit('updateData', item)
      this.showMask = false
    },
    //获取地址列表
    async getAddressList() {
      let res = await this.$axios.post('/api/Info/addressList')
      if (res.code == 1) {
        this.addressList = res.data
      }
    },
    handleAddAddr() {
      this.$refs.addrDialog.initData()
    },
  },
}
</script>

<style scoped lang="scss">
.setPassword {
  flex-wrap: wrap;
  .box-card {
    width: 49%;
    margin-right: 2%;
    margin-bottom: 16px;
    cursor: pointer;
    &:nth-child(2n) {
      margin-right: 0;
    }
    .name {
      margin-right: 6px;
    }
    .addr {
      padding-right: 20px;
      position: relative;
      color: #999;
      margin-top: 10px;
      .edit {
        position: absolute;
        right: 0;
        bottom: 2px;
      }
    }
  }
  .default-tag,
  .card-delete {
    float: right;
  }
  .card-delete {
    margin-left: 6px;
    position: relative;
    top: 2px;
  }
}
</style>
