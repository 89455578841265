<template>
  <el-dialog
    :title="$t('立即支付')"
    top="5%"
    :visible.sync="showMask"
    width="500px"
  >
    <div class="applyBox">
      <el-form
        :model="applyForm"
        :hide-required-asterisk="true"
        size="small"
        ref="ruleForm"
        label-width="110px"
      >
        <el-form-item
          :label="$t('币种：')"
          :rules="[
            {
              required: true,
              message: $t('请选择币种'),
              trigger: 'blur',
            },
          ]"
          prop="choice_curiency"
        >
          <el-select
            v-model="applyForm.choice_curiency"
            :disabled="!!payData.choice_curiency"
            @change="curiencyChange"
            :placeholder="$t('请选择币种')"
          >
            <el-option
              v-for="(item, index) in currencyList"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('公链：')"
          :rules="[
            {
              required: true,
              message: $t('请选择公链'),
              trigger: 'blur',
            },
          ]"
          prop="currency"
        >
          <!-- <el-input
            v-model="applyForm.currency"
            disabled
            :placeholder="$t('请选择公链')"
          >
          </el-input> -->
          <el-select
            v-model="applyForm.currency"
            :disabled="!!payData.currency"
            @change="gonglianChange"
            :placeholder="$t('请选择公链')"
          >
            <el-option
              v-for="(item, index) in gonglianList"
              :key="index"
              :label="item.chain"
              :value="item.chain"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('协议：')">
          <el-input
            :placeholder="$t('请选择协议')"
            v-model="PayMessage.agreement"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('钱包地址：')">
          <el-input
            :placeholder="$t('请选择钱包地址')"
            v-model="PayMessage.wallet_addr"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('收款金额：')" v-if="type == 4">
          <div class="bankMoney">USDT {{ payData.bank_money }}</div>
        </el-form-item>
        <el-form-item :label="$t('收款码：')">
          <img class="applyRwm" :src="PayMessage.qrimage" />
        </el-form-item>
        <el-form-item
          :label="$t('哈希值：')"
          :rules="[
            {
              required: true,
              message: $t('请输入付款哈希值信息'),
              trigger: 'blur',
            },
          ]"
          prop="hash"
        >
          <el-input
            v-model="applyForm.hash"
            :placeholder="$t('请输入付款哈希值信息')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('剩余支付时间：')">
          <div class="downTime">{{ downTime }}</div>
        </el-form-item>
      </el-form>
      <div class="tip" v-if="type == 2">
        {{ $t('reg_applySuccess_11') }}
      </div>
      <div class="tip" v-if="type == 3">
        {{ $t('my_usdPayment_7') }}
      </div>
      <div class="tip" v-if="type == 3">
        {{ $t('my_usdPayment_8') }}
        <span class="addr" @click="toMy('walletAddr')">
          {{ $t('my_usdPayment_9') }}
        </span>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="showMask = false">
        {{ $t('取消') }}
      </el-button>
      <el-button
        size="small"
        :loading="nextLoading"
        type="primary"
        @click="paySet"
      >
        {{ $t('已支付') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'Login',
  props: {
    isshow: {
      type: Boolean,
    },
    payData: {
      type: Object,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
      if (val === true) {
        this.getSeconds()
        this.PayMessage = {}
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate()
        })
      } else {
        clearInterval(this.timeCler)
      }
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
    payData(val) {
      this.applyForm = {
        ...val,
      }
      if (val.currency) {
        this.getPayMessage(val.currency)
      }
    },
  },
  data() {
    return {
      applyForm: {
        hash: '',
      },
      showMask: false,
      gonglianList: [],
      PayMessage: {},
      nextLoading: false,
      currencyList: [],
      timeCler: '',
      seconds: 0,
      downTime: '',
    }
  },
  created() {
    this.getCurrency()
  },
  methods: {
    async getCurrency() {
      let res = await this.$axios.post('/api/Common/getCurrency')
      if (res.code === 1) {
        this.currencyList = res.data
      }
    },
    async getSeconds() {
      let res = await this.$axios.post('/api/Config/countDown', {
        order_id: this.payData.order_id,
      })
      if (res.code === 1) {
        this.seconds = res.data * 60
        this.countDown()
        this.Time()
      }
    },
    countDown() {
      if (this.seconds == 0) {
        this.downTime = '已失效,请重新申请'
        clearInterval(this.timeCler)
        return
      }
      let m = parseInt((this.seconds / 60) % 60)
      m = m < 10 ? '0' + m : m
      let s = parseInt(this.seconds % 60)
      s = s < 10 ? '0' + s : s
      this.downTime = m + ' 分 ' + s + ' 秒'
    },
    Time() {
      this.timeCler = setInterval(() => {
        this.seconds -= 1
        this.countDown()
      }, 1000)
    },
    async curiencyChange(val) {
      let res = await this.$axios.post('/api/Exchange/getChain', {
        name: val,
      })
      if (res.code === 1) {
        this.applyForm.currency = ''
        this.PayMessage = {}
        this.gonglianList = res.data
      }
    },
    async gonglianChange(val) {
      let result = this.gonglianList.find((item) => {
        return item.chain == val
      })
      this.PayMessage = result
    },
    async getPayMessage(val) {
      let res = await this.$axios.post('/api/Config/payMessage', {
        name: val,
      })
      if (res.code === 1) {
        this.PayMessage = res.data
      }
    },
    paySet() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.applyForm = {
            ...this.applyForm,
            ...this.payData,
          }
          delete this.applyForm.choice_curiency
          this.nextLoading = true
          let res = await this.$axios.post('/api/Config/pay', this.applyForm)
          this.nextLoading = false
          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.$emit('updateData')
            this.showMask = false
          }
        }
      })
    },
    toMy(name) {
      let router = this.$router.resolve({
        name: 'My',
        params: { to: name },
      })
      window.open(router.href, '_blank')
    },
  },
}
</script>

<style scoped lang="scss">
.applyRwm {
  width: 120px;
  height: 120px;
}
.el-select {
  width: 100%;
}
.tip {
  font-size: 12px;
  color: #999999;
  padding-left: 20px;
  line-height: 20px;
  .addr {
    color: #409eff;
    cursor: pointer;
    margin-left: 6px;
  }
}
.downTime {
  color: red;
}
.bankMoney {
  color: #409eff;
}
</style>
