<template>
  <el-dialog
    :title="$t('my_bankCardDetail_1')"
    :visible.sync="showMask"
    width="500px"
  >
    <div class="setPassword">
      <div class="card-item">
        <span class="card-label">{{ $t('my_bankCardDetail_2') }}</span
        ><span class="card-con">{{ bankCard.nickname_zh }}</span>
      </div>
      <div class="card-item">
        <span class="card-label">{{ $t('my_bankCardDetail_3') }}</span
        ><span class="card-con">{{ bankCard.nickname_en }}</span>
      </div>
      <div class="card-item">
        <span class="card-label">{{ $t('my_bankCardDetail_4') }}</span
        ><span class="card-con">{{ bankCard.bank_number }}</span>
      </div>
      <div class="card-item">
        <span class="card-label">{{ $t('my_bankCardDetail_5') }}</span
        ><span class="card-con">{{ bankCard.bank_name }}</span>
      </div>
      <div class="card-item">
        <span class="card-label">{{ $t('my_bankCardDetail_6') }}</span
        ><span class="card-con">{{ bankCard.bank_name_en }}</span>
      </div>
      <div class="card-item">
        <span class="card-label">{{ $t('my_bankCardDetail_7') }}</span
        ><span class="card-con">{{ bankCard.swift_code }}</span>
      </div>
      <div class="card-item">
        <span class="card-label">{{ $t('my_bankCardDetail_8') }}</span
        ><span class="card-con">{{ bankCard.bank_addr }}</span>
      </div>
      <div class="card-item">
        <span class="card-label">{{ $t('my_bankCardDetail_9') }}</span
        ><span class="card-con">{{ bankCard.con_addr }}</span>
      </div>
      <div class="card-item">
        <span class="card-label">{{ $t('my_bankCardDetail_10') }}</span
        ><span class="card-con">{{ bankCard.con_phone }}</span>
      </div>
      <div class="card-item">
        <span class="card-label">{{ $t('my_bankCardDetail_11') }}</span
        ><span class="card-con">{{ bankCard.code }}</span>
      </div>
      <div class="card-item" v-if="bankCard.wuliu_status == 1">
        <span class="card-label">{{ $t('物流公司：') }}</span>
        <span class="card-con">{{ bankCard.logistics_name }}</span>
      </div>
      <div class="card-item" v-if="bankCard.wuliu_status == 1">
        <span class="card-label">{{ $t('物流单号：') }}</span>
        <span class="card-con">{{ bankCard.logistics_order }}</span>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="showMask = false">
        {{ $t('my_bankCardDetail_12') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'bankCardDetailDialog',
  props: {
    isshow: {
      type: Boolean,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
  },
  data() {
    return {
      showMask: false,
      nextLoading: false,
      bankCard: {},
    }
  },
  mounted() {},
  methods: {
    setBankCardData(data) {
      this.bankCard = data
    },
  },
}
</script>

<style scoped lang="scss">
.card-item {
  line-height: 26px;
}

.card-label {
  width: 150px;
  display: inline-block;
  text-align: right;
}

.card-con {
  font-size: 16px;
  font-weight: bold;
}
</style>
