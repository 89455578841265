<template>
  <el-dialog
    :title="$t('my_addBankCard_1')"
    top="5%"
    :visible.sync="showMask"
    width="500px"
  >
    <div class="setPassword">
      <el-form
        :model="ruleForm"
        status-icon
        size="small"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('my_addBankCard_2')">
          <el-input
            type="text"
            v-model="ruleForm.nickname_zh"
            :placeholder="$t('my_addBankCard_3')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('my_addBankCard_4')">
          <el-input
            type="text"
            v-model="ruleForm.nickname_en"
            :placeholder="$t('my_addBankCard_5')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('my_addBankCard_6')" prop="bank_number">
          <el-input
            type="text"
            v-model="ruleForm.bank_number"
            :placeholder="$t('my_addBankCard_7')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('my_addBankCard_8')">
          <el-input
            type="text"
            v-model="ruleForm.bank_name"
            :placeholder="$t('my_addBankCard_9')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('my_addBankCard_10')">
          <el-input
            type="text"
            v-model="ruleForm.bank_name_en"
            :placeholder="$t('my_addBankCard_11')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('my_addBankCard_12')" prop="swift_code">
          <el-input
            type="text"
            v-model="ruleForm.swift_code"
            :placeholder="$t('my_addBankCard_13')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('my_addBankCard_14')" prop="bank_addr">
          <el-input
            type="text"
            v-model="ruleForm.bank_addr"
            :placeholder="$t('my_addBankCard_15')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('my_addBankCard_16')" prop="con_addr">
          <el-input
            type="text"
            v-model="ruleForm.con_addr"
            :placeholder="$t('my_addBankCard_17')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('my_addBankCard_18')" prop="con_phone">
          <el-input
            type="text"
            v-model.number="ruleForm.con_phone"
            :placeholder="$t('my_addBankCard_19')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('my_addBankCard_20')" prop="code">
          <el-input
            type="text"
            v-model="ruleForm.code"
            :placeholder="$t('my_addBankCard_21')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="showMask = false">
        {{ $t('my_addBankCard_22') }}
      </el-button>
      <el-button
        :loading="nextLoading"
        size="small"
        type="primary"
        @click="showCheckPayPassword"
      >
        {{ $t('my_addBankCard_23') }}
      </el-button>
    </span>
    <check-pay-password-dialog
      @checkPayPassword="checkPayPassword"
      :isshow.sync="isCheckPayPasswordDialog"
    ></check-pay-password-dialog>
  </el-dialog>
</template>

<script>
import checkPayPasswordDialog from '@/components/pay/checkPayPasswordDialog.vue'
export default {
  name: 'addBankCardDialog',
  components: {
    checkPayPasswordDialog,
  },
  props: {
    isshow: {
      type: Boolean,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
  },
  data() {
    let that = this
    return {
      ruleForm: {
        nickname_zh: '',
        nickname_en: '',
        bank_number: '',
        bank_name: '',
        bank_name_en: '',
        swift_code: '',
        bank_addr: '',
        con_addr: '',
        con_phone: '',
        code: '',
      },
      rules: {
        bank_number: [
          {
            required: true,
            message: that.$t('my_addBankCard_24'),
            trigger: 'blur',
          },
        ],
        swift_code: [
          {
            required: true,
            message: that.$t('my_addBankCard_25'),
            trigger: 'blur',
          },
        ],
        bank_addr: [
          {
            required: true,
            message: that.$t('my_addBankCard_26'),
            trigger: 'blur',
          },
        ],
        con_addr: [
          {
            required: true,
            message: that.$t('my_addBankCard_27'),
            trigger: 'blur',
          },
        ],
        con_phone: [
          {
            required: true,
            message: that.$t('my_addBankCard_28'),
            trigger: 'blur',
          },
          {
            type: 'number',
            trigger: 'blur',
            message: that.$t('手机号格式不正确'),
          },
        ],
        code: [
          {
            required: true,
            message: that.$t('my_addBankCard_29'),
            trigger: 'blur',
          },
        ],
      },
      showMask: false,
      nextLoading: false,
      isCheckPayPasswordDialog: false,
      checkPayPasswordRes: 0,
    }
  },
  mounted() {},
  methods: {
    initData(item) {
      if (item) {
        this.ruleForm = item
        this.ruleForm.con_phone = parseInt(this.ruleForm.con_phone)
      } else {
        this.ruleForm = {}
      }
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
    checkPayPassword(checkPayPassword) {
      this.checkPayPasswordRes = checkPayPassword
    },
    showCheckPayPassword() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.checkPayPasswordRes == 1) {
            this.savePass()
          } else {
            this.isCheckPayPasswordDialog = true
          }
        }
      })
    },
    async savePass() {
      let url = ''
      if (this.ruleForm.id) {
        url = '/api/Info/saveBank'
      } else {
        url = '/api/Info/addBank'
      }
      this.nextLoading = true
      let res = await this.$axios.post(url, this.ruleForm)
      this.nextLoading = false
      if (res.code === 1) {
        this.showMask = false
        this.$emit('updateData')
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
