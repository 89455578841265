<template>
  <div class="user-box">
    <!-- 个人资料 -->
    <datum :userInfo="userInfo" @updateUserInfo="initData"></datum>
    <!-- 安全登陆绑定 -->
    <div id="binding">
      <logBinding :userInfo="userInfo" @updateUserInfo="initData"></logBinding>
    </div>

    <!-- 安全设置 -->
    <safetySet></safetySet>
  </div>
</template>

<script>
import datum from './datum.vue'
import logBinding from './logBinding.vue'
import safetySet from './safetySet.vue'
export default {
  name: 'About',
  components: {
    datum,
    logBinding,
    safetySet,
  },
  data() {
    return {
      list: '',
      userInfo: {},
    }
  },
  created() {
    this.initData()
  },
  methods: {
    scrTo(name) {
      const jump = document.getElementById(name)
      const scrollTop = jump.offsetTop // 获取需要滚动的距离
      // Chrome
      document.documentElement.scrollTo({
        top: scrollTop,
        behavior: 'smooth', // 平滑滚动
      })
    },
    async initData() {
      let res = await this.$axios.post('/api/Info/index')
      if (res.code === 1) {
        this.userInfo = res.data
      }
    },
  },
}
</script>

<style lang="scss"></style>
