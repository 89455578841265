<template>
  <div class="limit user-box">
    <div class="user-tit">
      <div class="ls"></div>
      <div class="text">{{ $t('my_limit_1') }}</div>
    </div>
    <div class="datum-cont">
      <div class="user-item">
        <div class="lable">{{ $t('my_limit_2') }}：</div>
        <div class="name">$ {{ list.all_money }}</div>
      </div>
      <div class="user-item">
        <div class="lable">{{ $t('my_limit_3') }}：</div>
        <div class="progress">
          <el-progress
            :text-inside="true"
            :format="progressFormat"
            :stroke-width="26"
            :percentage="percentage"
          ></el-progress>
          <div class="surplus">{{ $t('my_limit_4') }}: ${{ list.shengyu }}</div>
        </div>
      </div>
      <div class="user-item">
        <div class="lable">{{ $t('my_limit_5') }}：</div>
        <div class="limitApply flex-between">
          <div class="toLimit" @click="jumpTo('Enhance')">
            {{ $t('my_limit_6') }}...
          </div>
          <div class="limitApply-right" @click="isRecord = true">
            {{ $t('my_limit_7') }}
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="user-item">
        <div class="lable">{{ $t('my_limit_8') }}：</div>
        <div class="limitApply flex-between">
          <div class="toLimit" @click="jumpTo('Conversion')">
            {{ $t('点击立即兑换') }}...
          </div>
          <div class="limitApply-right" @click="isForRecord = true">
            {{ $t('兑换记录') }}
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <!-- <div class="user-item conversion-bar">
        <el-button type="success" plain @click="jumpTo('Conversion')">
          {{ $t('my_limit_8') }}
        </el-button>
      </div> -->
    </div>
    <record :isshow.sync="isRecord"></record>
    <forRecord :isshow.sync="isForRecord"></forRecord>
  </div>
</template>

<script>
import record from './record.vue'
import forRecord from './forRecord.vue'
export default {
  components: {
    record,
    forRecord,
  },
  data() {
    return {
      list: '',
      percentage: 0,
      isRecord: false,
      isForRecord: false,
    }
  },
  created() {
    this.getMoney()
  },
  methods: {
    async getMoney() {
      let res = await this.$axios.post('/api/Exchange/money')
      if (res.code === 1) {
        this.list = res.data
        if (res.data.all_money != 0) {
          this.percentage = res.data.use / res.data.all_money
        } else {
          this.percentage = 0
        }
      }
    },
    progressFormat() {
      let parst = this.$t('my_limit_9') + '：$ ' + this.list.use
      return parst
    },
    jumpTo(pageName) {
      this.$router.push({ name: pageName })
    },
  },
}
</script>

<style scoped lang="scss">
.datum-cont {
  padding: 24px 0 6px 36px;
}
.progress {
  position: relative;
  padding: 4px 0;
  /deep/ .el-progress-bar__inner {
    background: linear-gradient(90deg, #53a6e6 0%, #2c4dff 100%);
  }
  .surplus {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 12px;
    color: #999999;
  }
}
.limitApply {
  height: 32px;
  line-height: 32px;
  background: #f6f6f6;
  border-radius: 4px;
  padding: 0 10px;
  .toLimit {
    color: #409eff;
    cursor: pointer;
  }
  .limitApply-right {
    color: #999999;
    cursor: pointer;
  }
}
</style>
