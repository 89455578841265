import { render, staticRenderFns } from "./addWalletAddrDialog.vue?vue&type=template&id=6a37c956&scoped=true&"
import script from "./addWalletAddrDialog.vue?vue&type=script&lang=js&"
export * from "./addWalletAddrDialog.vue?vue&type=script&lang=js&"
import style0 from "./addWalletAddrDialog.vue?vue&type=style&index=0&id=6a37c956&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a37c956",
  null
  
)

export default component.exports