<template>
  <el-dialog :title="$t('兑换记录')" :visible.sync="showMask" width="900px">
    <div class="record">
      <el-form
        size="small"
        :inline="true"
        :model="searchData"
        label-width="70px"
      >
        <el-form-item :label="$t('兑换类型')">
          <el-select
            v-model="searchData.change_type"
            @change="getApplyRecord"
            clearable
            :placeholder="$t('请选择')"
          >
            <el-option :label="$t('兑换为美金')" :value="1" />
            <el-option :label="$t('兑换为货币')" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('审核状态')">
          <el-select
            v-model="searchData.status"
            @change="getApplyRecord"
            clearable
            :placeholder="$t('请选择')"
          >
            <el-option :label="$t('my_record_6')" :value="1" />
            <el-option :label="$t('my_record_7')" :value="2" />
            <el-option :label="$t('my_record_8')" :value="3" />
          </el-select>
        </el-form-item>
      </el-form>
      <el-table class="wallet-table" :data="tableList" border size="small">
        <el-table-column :label="$t('兑换类型')" width="90px">
          <template slot-scope="scope">
            <span v-if="scope.row.change_type == 1">
              {{ $t('兑换为美金') }}
            </span>
            <span v-if="scope.row.change_type == 2">
              {{ $t('兑换为货币') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="choice_curiency"
          :label="$t('币种')"
        ></el-table-column>
        <el-table-column
          prop="amount"
          :label="$t('兑换金额')"
        ></el-table-column>
        <el-table-column
          prop="actual_num"
          width="100px"
          :label="$t('实际到账数量')"
        ></el-table-column>
        <el-table-column
          prop="amount_fee"
          :label="$t('手续费')"
        ></el-table-column>
        <el-table-column :label="$t('后台付款凭证')" width="110">
          <template slot-scope="scope">
            <div v-if="scope.row.moneyimage">
              <el-image
                style="max-width: 90px; max-height: 90px"
                :src="scope.row.moneyimage"
                :preview-src-list="[scope.row.moneyimage]"
                fit="cover"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="moneyorder"
          width="140"
          :label="$t('hash/银行卡订单号')"
        ></el-table-column>
        <el-table-column :label="$t('审核状态')">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">{{ $t('my_record_6') }}</span>
            <span v-if="scope.row.status == 2">{{ $t('my_record_7') }}</span>
            <span v-if="scope.row.status == 3">{{ $t('my_record_8') }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('驳回原因')" width="110px">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              @click="openReject(scope.row.back_content)"
              v-if="scope.row.status == 3"
              >{{ $t('查看详情') }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="createtime" :label="$t('时间')" width="140px">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="searchData.current"
          :page-size="searchData.size"
          background
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" @click="showMask = false">
        {{ $t('my_record_9') }}
      </el-button>
    </span>
    <el-dialog
      :append-to-body="true"
      :title="$t('驳回原因')"
      :visible.sync="showReject"
      width="700px"
    >
      <div class="record">
        {{ reject }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="showReject = false">
          {{ $t('my_record_9') }}
        </el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
export default {
  name: 'addBankCardDialog',
  props: {
    isshow: {
      type: Boolean,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
  },
  data() {
    return {
      tableList: [],
      total: 0,
      searchData: {
        current: 1,
        size: 10,
        change_type: '',
        status: '',
      },
      showMask: false,
      showReject: false,
      reject: '',
    }
  },
  created() {
    this.searchData.current = 1
    this.getApplyRecord()
  },
  methods: {
    //获取兑换记录列表
    async getApplyRecord() {
      this.tableLoading = true
      let res = await this.$axios.post(
        '/api/Exchange/exchangeRecord',
        this.searchData
      )
      this.tableLoading = false
      if (res.code === 1) {
        this.total = res.data.total
        this.tableList = res.data.list
      }
    },
    handleCurrentChange(val) {
      this.searchData.current = val
      this.getApplyRecord()
    },
    openReject(item) {
      this.reject = item
      this.showReject = true
    },
  },
}
</script>

<style scoped lang="scss"></style>
