<template>
  <div class="invitation user-box">
    <div class="user-tit">
      <div class="ls"></div>
      <div class="text">{{ $t('邀请注册') }}</div>
    </div>
    <div class="invitation-cont">
      <div class="invitation-top flex-between">
        <div class="tip">
          {{ $t('邀请说明') }}： {{ $t('邀请有奖，注册费用返佣奖励') }}
        </div>
        <el-button size="small" class="solt-button" plain @click="handleCopy">
          {{ $t('立即邀请') }}
        </el-button>
      </div>
      <div class="bankCard-table">
        <el-table :data="invite_list" border size="small">
          <el-table-column
            prop="uuid"
            :label="$t('被邀请人UID')"
          ></el-table-column>
          <el-table-column
            prop="username"
            :label="$t('被邀请人姓名')"
          ></el-table-column>
          <el-table-column
            prop="email"
            :label="$t('被邀请人邮箱')"
          ></el-table-column>
          <el-table-column :label="$t('审核状态')">
            <template slot-scope="scope">
              <div v-if="scope.row.status == 1">未审核</div>
              <div v-else-if="scope.row.status == 2">已审核</div>
              <div v-else-if="scope.row.status == 3">驳回</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="reward"
            :label="$t('邀请奖励')"
          ></el-table-column>
          <el-table-column :label="$t('发放状态')">
            <template slot-scope="scope">
              <div v-if="scope.row.send_status == 1">未发放</div>
              <div v-else-if="scope.row.send_status == 2">已发放</div>
              <div v-else-if="scope.row.send_status == 3">驳回</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createtime"
            :label="$t('邀请时间')"
          ></el-table-column>
        </el-table>
        <div class="pager">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="邀请链接"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <el-input v-model="this.invite_url"></el-input>
        <el-link class="copy-link" type="primary" size="mini" @click="doCopy"
          >复制链接</el-link
        >
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      current: 1,
      size: 10,
      total: 0,
      list: '',
      invite_url: '',
      invite_list: [],
      tableData: [
        {
          date: '1',
          name: '1',
        },
      ],
    }
  },
  created() {
    this.initData()
  },
  methods: {
    handleCurrentChange(val) {
      this.current = val
      this.initData()
    },
    handleCopy() {
      this.dialogVisible = true
    },
    doCopy() {
      var _that = this
      this.$copyText(this.invite_url).then(
        function () {
          _that.$message({ type: 'success', message: '内容已复制到剪切板' })
        },
        function () {
          _that.$message({ type: 'error', message: '不支持复制' })
        }
      )
    },
    handleClose() {
      this.dialogVisible = false
    },
    async initData() {
      let res = await this.$axios.post('/api/Info/inviteList', {
        current: this.current,
      })
      if (res.code === 1) {
        this.invite_url = res.data.invite_url
        this.invite_list = res.data.list
        this.total = res.data.total
      }
    },
  },
}
</script>

<style scoped lang="scss">
.invitation {
  min-height: 300px;
}

.invitation-cont {
}

.invitation-tit {
  margin: 10px 0;
  font-size: 12px;
  color: #333333;
}

.invitation-top {
  margin-top: 10px;
  align-items: center;
  height: 32px;
  background: #f6f6f6;
  border-radius: 4px;
  padding: 20px 10px;

  .tip {
    font-size: 12px;
    color: #999999;
  }

  .solt-button {
    height: 24px;
    line-height: 24px;
    padding: 0 6px;
  }
}

.bankCard-table {
  margin-top: 20px;
}

.copy-link {
  margin-top: 10px;
}
.pager {
  margin: 20px auto;
  text-align: center;
}
</style>
