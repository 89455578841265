<template>
  <div class="datum">
    <div class="user-tit">
      <div class="ls"></div>
      <div class="text">{{ $t('my_logBinding_1') }}</div>
    </div>
    <div class="datum-cont">
      <div class="user-item">
        <div class="lable">
          <img class="lable-img" src="@/assets/img/my/goole.png" />
          {{ $t('my_logBinding_2') }}：
          <el-switch
            v-model="userInfo.is_google_verity"
            :active-value="1"
            :inactive-value="0"
            @change="googleVer"
          ></el-switch>
        </div>
        <div class="solt flex-start" v-if="userInfo.is_google_verity == 1">
          <div class="bindingBox">
            {{ userInfo.is_google_bind == 1 ? $t('已绑定') : $t('未绑定') }}
          </div>
          <el-button
            size="small"
            :loading="bindingLoad"
            @click="setBinding"
            class="solt-button"
            plain
          >
            {{ userInfo.is_google_bind == 1 ? $t('修改') : $t('绑定') }}
          </el-button>
        </div>
      </div>
      <div class="user-item">
        <div class="lable">
          <img class="lable-img" src="@/assets/img/my/email.png" />
          {{ $t('my_logBinding_5') }}：
        </div>
        <div class="email-cont flex-between">
          <div class="name">{{ userInfo.email }}</div>
          <i class="ion el-icon-circle-check"></i>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('Google绑定')"
      :visible.sync="isGoogleBinding"
      width="400px"
    >
      <div class="googleBinding">
        <div class="BindingTip">
          {{ $t('请使用Google验证器进行验证绑定操作') }}
        </div>
        <div class="rwm">
          <img :src="bindingForm.rwm" />
        </div>
        <el-form
          :model="bindingForm"
          :hide-required-asterisk="true"
          size="small"
          ref="ruleForm"
          label-width="68px"
          label-position="left"
          class="demo-ruleForm"
        >
          <el-form-item
            :label="$t('验证码')"
            :rules="[
              {
                required: true,
                message: $t('请输入验证码'),
                trigger: 'blur',
              },
            ]"
            prop="oneCode"
          >
            <el-input
              v-model="bindingForm.oneCode"
              :placeholder="$t('请输入验证码')"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="isGoogleBinding = false">
          {{ $t('取 消') }}
        </el-button>
        <el-button
          size="small"
          :loading="bindingLoad"
          type="primary"
          @click="bindingBut"
        >
          {{ $t('确定') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ['userInfo'],
  data() {
    return {
      list: {
        phoe: '11122334242',
      },
      isGoogleBinding: false,
      bindingLoad: false,
      bindingForm: {
        oneCode: '',
        rwm: '',
      },
    }
  },
  methods: {
    async googleVer(val) {
      let res = await this.$axios.post('/api/Config/setGoogleVerify', {
        verify: val,
      })
      if (res.code === 1) {
        this.$emit('updateUserInfo')
        this.$message({
          message: res.msg,
          type: 'success',
        })
      }
    },
    async setBinding() {
      this.bindingLoad = true
      let res = await this.$axios.post('/api/Config/bindGoogle')
      this.bindingLoad = false
      if (res.code === 1) {
        this.bindingForm.rwm = res.data
        this.bindingForm.oneCode = ''
        this.isGoogleBinding = true
      }
    },
    bindingBut() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.bindingLoad = true
          let res = await this.$axios.post('/api/Config/googleCode', {
            oneCode: this.bindingForm.oneCode,
          })
          this.bindingLoad = false
          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.$emit('updateUserInfo')
            this.isGoogleBinding = false
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.datum-cont {
  padding: 24px 16px 16px 36px;
}

.email-cont {
  .ion {
    font-size: 18px;
    color: #67c23a;
  }
}
.bindingBox {
  height: 32px;
  line-height: 32px;
  background: #f6f6f6;
  border-radius: 4px;
  padding: 0 10px;
  flex: 1;
  margin-right: 10px;
}
.googleBinding {
  .BindingTip {
    text-align: center;
    margin-bottom: 20px;
    color: #c0c4cc;
  }
  .rwm {
    width: 220px;
    height: 220px;
    padding: 10px;
    border: 1px solid #ccc;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
    img {
      width: 200px;
      height: 200px;
    }
  }
  .demo-ruleForm {
    .el-input {
      width: 224px;
    }
  }
}
</style>
