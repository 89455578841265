<template>
  <el-dialog :title="$t('my_addAddr_1')" :visible.sync="showMask" width="500px">
    <div class="setPassword">
      <el-form
        :model="ruleForm"
        :hide-required-asterisk="true"
        size="small"
        status-icon
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="$t('my_addAddr_2')"
          prop="username"
          :rules="[
            {
              required: true,
              message: $t('my_addAddr_3'),
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="text"
            v-model="ruleForm.username"
            :placeholder="$t('my_addAddr_4')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('my_addAddr_5')"
          prop="phone"
          :rules="[
            {
              required: true,
              message: $t('my_addAddr_6'),
              trigger: 'blur',
            },
            {
              type: 'number',
              trigger: 'blur',
              message: $t('手机号格式不正确'),
            },
          ]"
        >
          <el-input
            type="text"
            v-model.number="ruleForm.phone"
            :placeholder="$t('my_addAddr_7')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="country"
          :rules="[
            {
              required: true,
              message: $t('my_addAddr_8'),
              trigger: 'change',
            },
          ]"
          :label="$t('my_addAddr_9')"
        >
          <el-select
            v-model="ruleForm.country"
            filterable
            :placeholder="$t('my_addAddr_10')"
          >
            <el-option
              v-for="item in countryList"
              :key="item.id"
              :label="item.title"
              :value="item.title"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="city"
          :rules="[
            {
              required: true,
              message: $t('my_addAddr_11'),
              trigger: 'blur',
            },
          ]"
          :label="$t('my_addAddr_12')"
        >
          <el-input
            type="text"
            v-model="ruleForm.city"
            :placeholder="$t('my_addAddr_13')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="addr_desc"
          :rules="[
            {
              required: true,
              message: $t('my_addAddr_14'),
              trigger: 'blur',
            },
          ]"
          :label="$t('my_addAddr_15')"
        >
          <el-input
            type="text"
            v-model="ruleForm.addr_desc"
            :placeholder="$t('my_addAddr_16')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('my_addAddr_17')">
          <el-input
            type="text"
            v-model="ruleForm.code"
            :placeholder="$t('my_addAddr_18')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="" class="default">
          <el-checkbox v-model="defaults">
            {{ $t('my_addAddr_19') }}
          </el-checkbox>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="showMask = false">
        {{ $t('my_addAddr_20') }}
      </el-button>
      <el-button
        :loading="nextLoading"
        size="small"
        type="primary"
        @click="savePass"
      >
        {{ $t('my_addAddr_21') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'addBankCardDialog',

  props: {
    isshow: {
      type: Boolean,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
  },
  data() {
    return {
      countryList: {}, //国家列表
      ruleForm: {
        username: '',
        phone: '',
        country: '',
        city: '',
        addr_desc: '',
        code: '',
        is_default: 0,
      },
      defaults: false,
      showMask: false,
      nextLoading: false,
    }
  },
  created() {
    this.getCountry()
  },
  methods: {
    initData(item) {
      this.showMask = true
      this.defaults = false
      if (item) {
        this.ruleForm = {
          ...item,
        }
        if (item.is_default === 1) {
          this.defaults = true
        }
      } else {
        this.ruleForm = {
          username: '',
          phone: '',
          country: '',
          city: '',
          addr_desc: '',
          code: '',
          is_default: 0,
        }
      }
    },

    //获取国家列表
    async getCountry() {
      let res = await this.$axios.post('/api/Common/country')
      if (res.code == 1) {
        this.countryList = res.data
      }
    },
    savePass() {
      if (this.defaults) {
        this.ruleForm.is_default = 1
      } else {
        this.ruleForm.is_default = 0
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let url = ''
          if (this.ruleForm.id) {
            url = '/api/Info/saveUserAddress'
          } else {
            url = '/api/Info/addUserAddress'
          }
          this.nextLoading = true
          let res = await this.$axios.post(url, this.ruleForm)
          this.nextLoading = false
          if (res.code === 1) {
            this.showMask = false
            this.$emit('updateData')
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.default {
  margin-bottom: 0;
  /deep/ .el-form-item__content {
    line-height: 20px;
  }
}
</style>
