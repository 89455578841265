<template>
  <div>
    <el-dialog
      :append-to-body="true"
      :title="$t('安全验证')"
      :visible.sync="showMask"
      width="500px"
    >
      <div class="setPassword">
        <el-form
          :model="ruleForm"
          :hide-required-asterisk="true"
          status-icon
          ref="ruleForm"
          size="small"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item
            :label="$t('邮箱验证：')"
            :rules="[
              {
                required: true,
                message: $t('请输入邮箱验证码'),
                trigger: 'blur',
              },
            ]"
            prop="code"
          >
            <el-input
              v-model="ruleForm.code"
              :placeholder="$t('请输入邮箱验证码')"
              autocomplete="off"
            >
              <template slot="suffix">
                <span
                  :class="disEmail ? 'dis' : ''"
                  class="suffix-text"
                  @click="sendEmail"
                >
                  {{ sendEmailText }}
                </span>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          :loading="nextLoading"
          size="small"
          type="primary"
          @click="savePass"
        >
          {{ $t('确认') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'checkPayPasswordDialog',
  props: {
    isshow: {
      type: Boolean,
    },
    type: {
      type: String,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
      if (val === true) {
        this.initData()
      }
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
  },
  data() {
    return {
      ruleForm: {
        code: '',
      },
      showMask: false,
      nextLoading: false,
      disEmail: false,
      time: 60,
      sendEmailText: this.$t('发送邮箱'),
    }
  },
  methods: {
    initData() {
      this.disEmail = false
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      })
    },
    async sendEmail() {
      if (this.disEmail) return
      this.disEmail = true
      let res = await this.$axios.post('/api/Ems/sendEmail', {
        event: this.type,
      })
      this.disEmail = false
      if (res.code === 1) {
        this.$message({
          message: res.msg,
          type: 'success',
        })
        this.time = 60
        this.timer()
      }
    },
    timer() {
      if (this.time > 0) {
        this.disEmail = true
        this.time--
        this.sendEmailText = this.time + 's'
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.sendEmailText = this.$t('发送邮箱')
        this.disEmail = false
      }
    },
    savePass() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.ruleForm.event = this.type
          this.nextLoading = true
          let res = await this.$axios.post('/api/User/checkPwd', this.ruleForm)
          this.nextLoading = false
          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.showMask = false
            this.$emit('checkPayPassword', res.code)
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.suffix-text {
  color: #409eff;
  cursor: pointer;
  &.dis {
    color: #999999;
  }
}
</style>
