<template>
  <div class="my-nav" :style="{ top: navTop }">
    <div
      class="nav-item"
      :class="{ active: activeMenu === 'user' }"
      @click="jumpTo('user')"
    >
      <i class="ion el-icon-user-solid"></i>
      <div class="name">{{ $t('my_nav_1') }}</div>
    </div>
    <div
      class="nav-item"
      :class="{ active: activeMenu === 'limit' }"
      @click="jumpTo('limit')"
    >
      <i class="ion el-icon-coin"></i>
      <div class="name">{{ $t('my_nav_2') }}</div>
    </div>
    <div
      class="nav-item"
      :class="{ active: activeMenu === 'walletAddr' }"
      @click="jumpTo('walletAddr')"
    >
      <i class="ion el-icon-s-finance"></i>
      <div class="name">{{ $t('my_nav_3') }}</div>
    </div>
    <div
      class="nav-item"
      :class="{ active: activeMenu === 'bankCard' }"
      @click="jumpTo('bankCard')"
    >
      <i class="ion el-icon-bank-card"></i>
      <div class="name">{{ $t('my_nav_4') }}</div>
    </div>
    <div
      class="nav-item"
      :class="{ active: activeMenu === 'invitation' }"
      @click="jumpTo('invitation')"
    >
      <i class="ion el-icon-paperclip"></i>
      <div class="name">{{ $t('my_nav_5') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navTop: 0,
      activeMenu: 'user',
      noscroll: true,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.setNavTop)
  },
  methods: {
    setNavTop() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      this.navTop = scrollTop + 'px'
      if (this.noscroll) {
        //可视区的高度
        const windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight
        //滚动条的总高度
        const scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight
        if (scrollTop + windowHeight == scrollHeight) {
          this.activeMenu = 'invitation'
          return
        }
        const topArr = this.$parent.getscrollBox(scrollTop)
        for (let i = 0; i < topArr.length; i++) {
          // 根据滚动距离判断应该滚动到第几个导航的位置
          if (scrollTop <= topArr[i].top) {
            this.activeMenu = topArr[i].name
            break
          }
        }
      }
    },
    jumpTo(pageName) {
      if (this.noscroll) {
        this.activeMenu = pageName
        this.noscroll = false
        this.$emit('scrollJump', pageName)
      }
    },
    scrollStatus(val) {
      this.noscroll = val
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.setNavTop)
  },
}
</script>

<style scoped lang="scss">
.my-nav {
  width: 220px;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px;
  // height: calc(100% - 40px);
  position: absolute;
  top: 0;
  left: 0;
}
.nav-item {
  height: 40px;
  font-size: 12px;
  color: #666666;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  .ion {
    font-size: 18px;
    margin-right: 18px;
  }
  &:hover {
    color: #66b1ff;
  }
  &.active {
    background: rgba(30, 145, 234, 0.3);
    border-radius: 4px;
    color: #66b1ff;
  }
}
</style>
