var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.$t('立即支付'),"top":"5%","visible":_vm.showMask,"width":"500px"},on:{"update:visible":function($event){_vm.showMask=$event}}},[_c('div',{staticClass:"applyBox"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.applyForm,"hide-required-asterisk":true,"size":"small","label-width":"110px"}},[_c('el-form-item',{attrs:{"label":_vm.$t('币种：'),"rules":[
          {
            required: true,
            message: _vm.$t('请选择币种'),
            trigger: 'blur',
          } ],"prop":"choice_curiency"}},[_c('el-select',{attrs:{"disabled":!!_vm.payData.choice_curiency,"placeholder":_vm.$t('请选择币种')},on:{"change":_vm.curiencyChange},model:{value:(_vm.applyForm.choice_curiency),callback:function ($$v) {_vm.$set(_vm.applyForm, "choice_curiency", $$v)},expression:"applyForm.choice_curiency"}},_vm._l((_vm.currencyList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":item}})}),1)],1),_c('el-form-item',{attrs:{"label":_vm.$t('公链：'),"rules":[
          {
            required: true,
            message: _vm.$t('请选择公链'),
            trigger: 'blur',
          } ],"prop":"currency"}},[_c('el-select',{attrs:{"disabled":!!_vm.payData.currency,"placeholder":_vm.$t('请选择公链')},on:{"change":_vm.gonglianChange},model:{value:(_vm.applyForm.currency),callback:function ($$v) {_vm.$set(_vm.applyForm, "currency", $$v)},expression:"applyForm.currency"}},_vm._l((_vm.gonglianList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.chain,"value":item.chain}})}),1)],1),_c('el-form-item',{attrs:{"label":_vm.$t('协议：')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('请选择协议'),"disabled":""},model:{value:(_vm.PayMessage.agreement),callback:function ($$v) {_vm.$set(_vm.PayMessage, "agreement", $$v)},expression:"PayMessage.agreement"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('钱包地址：')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('请选择钱包地址'),"disabled":""},model:{value:(_vm.PayMessage.wallet_addr),callback:function ($$v) {_vm.$set(_vm.PayMessage, "wallet_addr", $$v)},expression:"PayMessage.wallet_addr"}})],1),(_vm.type == 4)?_c('el-form-item',{attrs:{"label":_vm.$t('收款金额：')}},[_c('div',{staticClass:"bankMoney"},[_vm._v("USDT "+_vm._s(_vm.payData.bank_money))])]):_vm._e(),_c('el-form-item',{attrs:{"label":_vm.$t('收款码：')}},[_c('img',{staticClass:"applyRwm",attrs:{"src":_vm.PayMessage.qrimage}})]),_c('el-form-item',{attrs:{"label":_vm.$t('哈希值：'),"rules":[
          {
            required: true,
            message: _vm.$t('请输入付款哈希值信息'),
            trigger: 'blur',
          } ],"prop":"hash"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('请输入付款哈希值信息')},model:{value:(_vm.applyForm.hash),callback:function ($$v) {_vm.$set(_vm.applyForm, "hash", $$v)},expression:"applyForm.hash"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('剩余支付时间：')}},[_c('div',{staticClass:"downTime"},[_vm._v(_vm._s(_vm.downTime))])])],1),(_vm.type == 2)?_c('div',{staticClass:"tip"},[_vm._v(" "+_vm._s(_vm.$t('reg_applySuccess_11'))+" ")]):_vm._e(),(_vm.type == 3)?_c('div',{staticClass:"tip"},[_vm._v(" "+_vm._s(_vm.$t('my_usdPayment_7'))+" ")]):_vm._e(),(_vm.type == 3)?_c('div',{staticClass:"tip"},[_vm._v(" "+_vm._s(_vm.$t('my_usdPayment_8'))+" "),_c('span',{staticClass:"addr",on:{"click":function($event){return _vm.toMy('walletAddr')}}},[_vm._v(" "+_vm._s(_vm.$t('my_usdPayment_9'))+" ")])]):_vm._e()],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.showMask = false}}},[_vm._v(" "+_vm._s(_vm.$t('取消'))+" ")]),_c('el-button',{attrs:{"size":"small","loading":_vm.nextLoading,"type":"primary"},on:{"click":_vm.paySet}},[_vm._v(" "+_vm._s(_vm.$t('已支付'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }