var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.$t('my_addAddr_1'),"visible":_vm.showMask,"width":"500px"},on:{"update:visible":function($event){_vm.showMask=$event}}},[_c('div',{staticClass:"setPassword"},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"hide-required-asterisk":true,"size":"small","status-icon":"","label-width":"100px"}},[_c('el-form-item',{attrs:{"label":_vm.$t('my_addAddr_2'),"prop":"username","rules":[
          {
            required: true,
            message: _vm.$t('my_addAddr_3'),
            trigger: 'blur',
          } ]}},[_c('el-input',{attrs:{"type":"text","placeholder":_vm.$t('my_addAddr_4'),"autocomplete":"off"},model:{value:(_vm.ruleForm.username),callback:function ($$v) {_vm.$set(_vm.ruleForm, "username", $$v)},expression:"ruleForm.username"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('my_addAddr_5'),"prop":"phone","rules":[
          {
            required: true,
            message: _vm.$t('my_addAddr_6'),
            trigger: 'blur',
          },
          {
            type: 'number',
            trigger: 'blur',
            message: _vm.$t('手机号格式不正确'),
          } ]}},[_c('el-input',{attrs:{"type":"text","placeholder":_vm.$t('my_addAddr_7'),"autocomplete":"off"},model:{value:(_vm.ruleForm.phone),callback:function ($$v) {_vm.$set(_vm.ruleForm, "phone", _vm._n($$v))},expression:"ruleForm.phone"}})],1),_c('el-form-item',{attrs:{"prop":"country","rules":[
          {
            required: true,
            message: _vm.$t('my_addAddr_8'),
            trigger: 'change',
          } ],"label":_vm.$t('my_addAddr_9')}},[_c('el-select',{attrs:{"filterable":"","placeholder":_vm.$t('my_addAddr_10')},model:{value:(_vm.ruleForm.country),callback:function ($$v) {_vm.$set(_vm.ruleForm, "country", $$v)},expression:"ruleForm.country"}},_vm._l((_vm.countryList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.title,"value":item.title}})}),1)],1),_c('el-form-item',{attrs:{"prop":"city","rules":[
          {
            required: true,
            message: _vm.$t('my_addAddr_11'),
            trigger: 'blur',
          } ],"label":_vm.$t('my_addAddr_12')}},[_c('el-input',{attrs:{"type":"text","placeholder":_vm.$t('my_addAddr_13'),"autocomplete":"off"},model:{value:(_vm.ruleForm.city),callback:function ($$v) {_vm.$set(_vm.ruleForm, "city", $$v)},expression:"ruleForm.city"}})],1),_c('el-form-item',{attrs:{"prop":"addr_desc","rules":[
          {
            required: true,
            message: _vm.$t('my_addAddr_14'),
            trigger: 'blur',
          } ],"label":_vm.$t('my_addAddr_15')}},[_c('el-input',{attrs:{"type":"text","placeholder":_vm.$t('my_addAddr_16'),"autocomplete":"off"},model:{value:(_vm.ruleForm.addr_desc),callback:function ($$v) {_vm.$set(_vm.ruleForm, "addr_desc", $$v)},expression:"ruleForm.addr_desc"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('my_addAddr_17')}},[_c('el-input',{attrs:{"type":"text","placeholder":_vm.$t('my_addAddr_18'),"autocomplete":"off"},model:{value:(_vm.ruleForm.code),callback:function ($$v) {_vm.$set(_vm.ruleForm, "code", $$v)},expression:"ruleForm.code"}})],1),_c('el-form-item',{staticClass:"default",attrs:{"label":""}},[_c('el-checkbox',{model:{value:(_vm.defaults),callback:function ($$v) {_vm.defaults=$$v},expression:"defaults"}},[_vm._v(" "+_vm._s(_vm.$t('my_addAddr_19'))+" ")])],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.showMask = false}}},[_vm._v(" "+_vm._s(_vm.$t('my_addAddr_20'))+" ")]),_c('el-button',{attrs:{"loading":_vm.nextLoading,"size":"small","type":"primary"},on:{"click":_vm.savePass}},[_vm._v(" "+_vm._s(_vm.$t('my_addAddr_21'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }