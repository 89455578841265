<template>
  <div class="my mainBox flex-start">
    <div class="my-left">
      <my-nav ref="myNav" @scrollJump="scrollJump"></my-nav>
    </div>
    <div class="my-right">
      <div id="user">
        <user ref="userRef"></user>
      </div>
      <div id="limit">
        <limit></limit>
      </div>
      <div id="walletAddr">
        <walletAddr></walletAddr>
      </div>
      <div id="bankCard">
        <bankCard></bankCard>
      </div>
      <div id="invitation">
        <invitation></invitation>
      </div>
      <!-- <div id="myFooter" class="my-buttom"></div> -->
    </div>
  </div>
</template>

<script>
import myNav from './components/my-nav.vue'
import user from './user/index.vue'
import limit from './limit/index.vue'
import walletAddr from './walletAddr.vue'
import bankCard from './bankCard/bankCard.vue'
import invitation from './invitation.vue'
export default {
  name: 'About',
  components: {
    myNav,
    user,
    limit,
    walletAddr,
    bankCard,
    invitation,
  },
  data() {
    return {
      scrollData: ['user', 'limit', 'walletAddr', 'bankCard', 'invitation'],
    }
  },
  mounted() {
    if (this.$route.params.to) {
      const name = this.$route.params.to
      this.$nextTick(() => {
        if (name == 'binding') {
          this.$refs.userRef.scrTo(name)
        } else {
          this.$refs.myNav.jumpTo(name)
        }
      })
    }
  },
  methods: {
    scrollJump(name) {
      const jump = document.getElementById(name)
      const scrollTop = jump.offsetTop // 获取需要滚动的距离
      // Chrome
      document.documentElement.scrollTo({
        top: scrollTop,
        behavior: 'smooth', // 平滑滚动
      })
      let that = this
      setTimeout(() => {
        that.$refs.myNav.scrollStatus(true)
      }, 800)
    },
    getscrollBox() {
      let topArr = []
      for (let i = 0; i < this.scrollData.length; i++) {
        let par = {}
        par.name = this.scrollData[i]
        if (i == this.scrollData.length - 1) {
          par.top = document.getElementById(this.scrollData[i]).offsetTop + 2
        } else {
          par.top =
            document.getElementById(this.scrollData[i + 1]).offsetTop - 1
        }
        topArr.push(par)
      }
      return topArr
    },
  },
}
</script>

<style lang="scss">
.my {
  padding-bottom: 40px;
  margin-top: 16px;
  position: relative;
}
.my-left {
  width: 220px;
  margin-right: 16px;
}
.my-right {
  // width: calc(100% - 244px);
  flex: 1;
  min-height: 300px;
}
.user-box {
  background: #ffffff;
  border-radius: 8px;
  padding: 8px;
}
.user-tit {
  height: 32px;
  background: rgba(202, 210, 255, 0.3);
  border-radius: 4px;
  padding: 7px 8px;
  .ls {
    float: left;
    width: 4px;
    height: 16px;
    background: #409eff;
    border-radius: 2px;
  }
  .text {
    font-size: 18px;
    line-height: 18px;
    color: #333333;
    padding-left: 24px;
  }
}
.user-item {
  font-size: 12px;
  margin-bottom: 10px;
  .lable {
    color: #333333;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .lable-img {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
  }
  .name {
    color: #999999;
    height: 28px;
    line-height: 28px;
  }
  .solt {
    .solt-val {
      margin-right: 10px;
      .el-input__inner {
        background: #f6f6f6;
        border: 0;
      }
    }
  }
}
.solt-button {
  border-color: #409eff;
  color: #409eff;
  padding: 9px 6px;
}
.my-buttom {
  background: #fff;
  height: 1px;
  width: 100%;
}
</style>
