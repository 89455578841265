<template>
  <div class="datum">
    <div class="user-tit">
      <div class="ls"></div>
      <div class="text">
        {{ $t('my_safety_1') }}
        <el-button @click="unsubscribe" class="unsubscribe" type="text"
          >注销账户</el-button
        >
      </div>
    </div>
    <div class="datum-cont">
      <div class="user-item">
        <div class="lable">{{ $t('my_safety_2') }}：</div>
        <div class="solt flex-start">
          <el-input
            class="solt-val"
            size="small"
            show-password
            readonly
            v-model="list.phoe"
            :placeholder="$t('my_safety_3')"
          ></el-input>
          <el-button
            @click="modPassword"
            size="small"
            class="solt-button"
            plain
          >
            {{ $t('my_safety_4') }}
          </el-button>
        </div>
      </div>
      <div class="user-item">
        <div class="lable">{{ $t('my_safety_5') }}：</div>
        <div class="solt flex-start">
          <el-input
            class="solt-val"
            size="small"
            show-password
            readonly
            v-model="list.phoe"
            :placeholder="$t('my_safety_6')"
          ></el-input>
          <el-button
            size="small"
            class="solt-button"
            plain
            @click="showSetPayPasswordDialog"
          >
            {{ $t('my_safety_7') }}
          </el-button>
        </div>
      </div>
    </div>
    <setPassword :isshow.sync="isSetPassword"></setPassword>
    <set-pay-password :isshow.sync="isSetPayPassword"></set-pay-password>
  </div>
</template>

<script>
import setPassword from './setPassword.vue'
import setPayPassword from './setPayPassword'
export default {
  components: {
    setPassword,
    setPayPassword,
  },
  data() {
    return {
      list: {
        phoe: '11122334242',
      },
      isSetPassword: false,
      isSetPayPassword: false,
    }
  },
  methods: {
    modPassword() {
      this.isSetPassword = true
    },
    showSetPayPasswordDialog() {
      this.isSetPayPassword = true
    },
    unsubscribe() {
      this.$confirm(this.$t('您确认要注销账户吗?'), this.$t('提示'), {
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'warning',
      })
        .then(async () => {
          this.nextLoading = true
          let res = await this.$axios.post('/api/User/zhuxiao')
          this.nextLoading = false
          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'success',
            })
            localStorage.removeItem('sm_token')
            localStorage.removeItem('sm_email')
            localStorage.removeItem('sm_type')
            localStorage.removeItem('sm_userinfo')
            this.$router.push({ name: 'Login' })
          }
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="scss">
.datum-cont {
  padding: 24px 16px 16px 36px;
}
.email-cont {
  .ion {
    font-size: 18px;
    color: #67c23a;
  }
}
.unsubscribe {
  float: right;
  padding: 0;
  margin-top: 1px;
}
</style>
