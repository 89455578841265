<template>
  <div class="bankCard user-box">
    <div class="user-tit">
      <div class="ls"></div>
      <div class="text">{{ $t('my_bankCard_1') }}</div>
    </div>
    <div class="wallet-cont">
      <div class="bankCard-top">
        <el-button
          size="small"
          :loading="metaBankLoading"
          class="solt-button"
          plain
          @click="metaBank"
        >
          {{ $t('申请 Meta Bank卡') }}
        </el-button>
        <el-button
          size="small"
          class="solt-button"
          plain
          @click="handleAddBankCard"
        >
          {{ $t('my_bankCard_2') }}
        </el-button>
        <el-button size="small" class="solt-button" plain @click="handleaddr">
          {{ $t('地址管理') }}
        </el-button>
      </div>
      <div class="bankCard-list flex-start">
        <div class="list-item" v-for="item in bankList.bank" :key="item.id">
          <div class="bankCard-name">
            <div>{{ item.bank_name }}</div>
            <i
              class="el-icon-delete bankCard-delete"
              @click="deleteBankCard(item.id)"
            ></i>
          </div>
          <div class="bankCard-name en">
            <div>{{ item.bank_name_en }}</div>
            <i
              class="el-icon-edit bankCard-edit"
              @click="editBankCard(item)"
            ></i>
          </div>
          <div class="card flex-between">
            <div>
              {{ $t('my_bankCard_3') }}
              <el-tag
                style="margin-left: 6px"
                size="small"
                effect="dark"
                v-if="item.bank_type == 1"
                type="warning"
              >
                {{ $t('自有卡') }}
              </el-tag>
              <el-tag
                size="small"
                v-else
                effect="dark"
                style="margin-left: 6px"
                type="warning"
              >
                {{ $t('Meta Bank') }}
              </el-tag>
            </div>
          </div>
          <div class="card-num">{{ item.bank_number }}</div>
          <div class="status" v-if="item.status == 1">
            {{ $t('my_bankCard_17') }}
          </div>
          <div class="status" v-else-if="item.real_card == 1">
            {{ $t('my_bankCard_4') }}
          </div>
          <div class="status" v-else-if="item.real_card == 2">
            {{ $t('my_bankCard_5') }}
          </div>
          <div class="status" v-else></div>
          <div class="but flex-start">
            <div
              class="but-item"
              :class="
                item.bank_type == 1 ||
                item.real_card == 2 ||
                item.real_card == 3
                  ? 'wid'
                  : ''
              "
              @click="handleBankCardDetail(item)"
            >
              {{ $t('my_bankCard_6') }}
            </div>
            <div
              v-if="item.bank_type == 2 && item.real_card == 0"
              class="but-item right"
              @click="handleApplyRealCard(item)"
            >
              {{ $t('my_bankCard_7') }}
            </div>
            <div
              v-if="item.real_card == 1 || item.real_card == 4"
              class="but-item right"
              @click="handleCancelApply(item)"
            >
              {{ $t('my_bankCard_8') }}
            </div>
          </div>
        </div>
        <div class="list-item" v-for="item in bankList.list" :key="item.id">
          <div class="metaNAme">
            <el-tag size="small" effect="dark" type="warning">
              {{ $t('Meta Bank卡') }}
            </el-tag>
          </div>
          <div class="metaStatus">
            状态：
            <span v-if="item.status == 1">待审核</span>
            <span v-if="item.status == 2">已审核</span>
            <span v-if="item.status == 3">已驳回</span>
          </div>
        </div>
        <el-empty
          class="empty"
          v-if="bankList.length <= 0"
          description="暂无银行卡片，快来添加吧！"
        />
      </div>
    </div>
    <add-bank-card-dialog
      :isshow.sync="isAddBankCardDialog"
      ref="addBankCard"
      @updateData="initData"
    ></add-bank-card-dialog>
    <bank-card-detail-dialog
      ref="bankCardDetailDialog"
      :isshow.sync="isBankCardDetailDialog"
    ></bank-card-detail-dialog>
    <apply-real-card-dialog
      ref="applyRealCardDialog"
      :isshow.sync="isApplyRealCardDialog"
      @updateData="initData"
    ></apply-real-card-dialog>
    <select-addr-dialog
      :isshow.sync="isSelectAddrDialog"
      :type="2"
    ></select-addr-dialog>
    <usdPayment
      :payData="payData"
      :type="4"
      :isshow.sync="isUsdPayment"
      @updateData="updateDataFu"
    ></usdPayment>
  </div>
</template>

<script>
import addBankCardDialog from './addBankCardDialog.vue'
import bankCardDetailDialog from './bankCardDetailDialog.vue'
import applyRealCardDialog from './applyRealCardDialog.vue'
import selectAddrDialog from './selectAddrDialog.vue'
import usdPayment from '@/components/pay/usdPayment.vue'

export default {
  components: {
    addBankCardDialog,
    bankCardDetailDialog,
    applyRealCardDialog,
    selectAddrDialog,
    usdPayment,
  },
  data() {
    return {
      isAddBankCardDialog: false,
      isBankCardDetailDialog: false,
      isApplyRealCardDialog: false,
      isSelectAddrDialog: false,
      metaBankLoading: false,
      bankList: {
        bank: [],
        list: [],
      },
      payData: {
        type: 6,
      },
      isUsdPayment: false,
      tableData: [
        {
          date: '1',
          name: '1',
        },
      ],
    }
  },
  created() {
    this.initData()
  },
  methods: {
    handleAddBankCard() {
      this.isAddBankCardDialog = true
      this.$refs.addBankCard.initData()
    },
    editBankCard(item) {
      this.isAddBankCardDialog = true
      this.$refs.addBankCard.initData(JSON.parse(JSON.stringify(item)))
    },
    async initData() {
      let res = await this.$axios.post('/api/Info/bankList')
      if (res.code === 1) {
        this.bankList = res.data
      }
    },
    handleaddr() {
      this.isSelectAddrDialog = true
    },
    updateDataFu() {
      let that = this
      this.$alert(that.$t('my_enhance_9'), that.$t('my_enhance_10'), {
        confirmButtonText: that.$t('my_enhance_11'),
      })
      this.initData()
    },
    async metaBank() {
      this.metaBankLoading = true
      let res = await this.$axios.post('/api/Info/getMetaBank')
      this.metaBankLoading = false
      if (res.code === 1) {
        this.payData = {
          ...this.payData,
          ...res.data,
        }
        this.isUsdPayment = true
      }
    },
    deleteBankCard(id) {
      let _that = this
      this.$confirm(_that.$t('my_bankCard_9?'), _that.$t('my_bankCard_10'), {
        confirmButtonText: _that.$t('my_bankCard_11'),
        cancelButtonText: _that.$t('my_bankCard_12'),
        type: 'warning',
      })
        .then(async () => {
          let res = await this.$axios.post('/api/Info/delBank', { id: id })
          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.initData()
          }
        })
        .catch(() => {})
    },
    handleBankCardDetail(item) {
      this.$refs.bankCardDetailDialog.setBankCardData(item)
      this.isBankCardDetailDialog = true
    },
    //申请实体卡
    handleApplyRealCard(item) {
      this.$refs.applyRealCardDialog.setBankCardData(item)
      this.isApplyRealCardDialog = true
      console.log(item)
    },
    //取消申请
    handleCancelApply(item) {
      let _that = this
      this.$confirm(_that.$t('my_bankCard_13'), _that.$t('my_bankCard_14'), {
        confirmButtonText: _that.$t('my_bankCard_15'),
        cancelButtonText: _that.$t('my_bankCard_16'),
        type: 'warning',
      })
        .then(async () => {
          let res = await this.$axios.post('/api/Info/quBank', { id: item.id })
          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.initData()
          }
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="scss">
.bankCard {
  min-height: 300px;
}

.wallet-cont {
  padding: 10px 0 10px 10px;
}

.bankCard-top {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .solt-button {
    height: 24px;
    line-height: 24px;
    padding: 0 6px;
    margin-right: 14px;
  }

  .administration {
    font-size: 12px;
    color: #999999;
    cursor: pointer;
  }
}

.bankCard-list {
  flex-wrap: wrap;

  .list-item {
    display: inline-block;
    margin-right: 10px;
    padding: 16px;
    min-height: 187px;
    width: 302px;
    background: linear-gradient(90deg, #e87b6e 0%, #cf5358 100%);
    border-radius: 8px;
    font-size: 12px;
    color: #ffffff;
    margin-top: 14px;
    .metaNAme {
      margin-top: 16px;
      text-align: center;
    }
    .metaStatus {
      margin-top: 16px;
    }
  }

  .bankCard-name {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.en {
      margin-top: 6px;
    }

    .lable-img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .bankCard-delete {
      cursor: pointer;
    }
  }
  .bankCard-edit {
    cursor: pointer;
  }

  .card,
  .status {
    margin: 6px 0;
  }
  .status {
    height: 16px;
  }

  .but-item {
    height: 32px;
    line-height: 32px;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #ffffff;
    width: 50%;
    text-align: center;
    cursor: pointer;
    &.wid {
      width: 100%;
    }

    &.right {
      background: #ffffff;
      border-radius: 0px 4px 4px 0px;
      color: #d75f5f;
    }
  }
}
.empty {
  margin: 0 auto;
}
</style>
