<template>
  <div class="datum">
    <div class="user-tit">
      <div class="ls"></div>
      <div class="text">{{ $t('my_datum_1') }}</div>
    </div>
    <div class="datum-cont">
      <div class="user-item">
        <div class="lable">{{ $t('my_datum_2') }}：</div>
        <div class="name">{{ userInfo.username }}</div>
      </div>
      <div class="user-item">
        <div class="lable">{{ $t('my_datum_3') }}：</div>
        <div class="solt flex-start">
          <el-input
            class="solt-val"
            size="small"
            readonly
            v-model="userInfo.mobile"
            :placeholder="$t('my_datum_4')"
          ></el-input>
          <el-button
            size="small"
            class="solt-button"
            plain
            @click="showSetMobileDialog"
          >
            {{ $t('my_datum_5') }}
          </el-button>
        </div>
      </div>
      <div class="user-item">
        <div class="lable">{{ $t('my_datum_6') }}：</div>
        <div class="name">{{ userInfo.country_name }}</div>
      </div>
      <div class="user-item">
        <div class="lable">{{ $t('my_datum_7') }}：</div>
        <div class="solt flex-start">
          <el-input
            class="solt-val"
            size="small"
            readonly
            v-model="userInfo.passportcode"
            :placeholder="$t('my_datum_8')"
          ></el-input>
          <el-button
            size="small"
            class="solt-button"
            plain
            @click="showSetPassportDialog"
          >
            {{ $t('my_datum_9') }}
          </el-button>
        </div>
      </div>
      <div class="user-item">
        <div class="lable">{{ $t('my_datum_10') }}：</div>
        <div class="identity-img">
          <img class="img-last" :src="userInfo.passportimage" />
          <img :src="userInfo.positiveimage" />
          <img :src="userInfo.sideimage" />
        </div>
      </div>
    </div>
    <set-mobile-dialog
      :isshow.sync="isSetMobile"
      :mobile="userInfo.mobile"
    ></set-mobile-dialog>
    <set-passport-code-dialog
      :isshow.sync="isSetPassportCode"
      :passportcode="userInfo.passportcode"
    ></set-passport-code-dialog>
  </div>
</template>

<script>
import setMobileDialog from './setMobileDialog.vue'
import setPassportCodeDialog from './setPassportCodeDialog.vue'

export default {
  components: {
    setMobileDialog,
    setPassportCodeDialog,
  },
  props: ['userInfo'],
  data() {
    return {
      list: '',
      isSetMobile: false,
      isSetPassportCode: false,
    }
  },
  created() {},
  watch: {
    isSetMobile(val) {
      if (val == false) {
        this.$emit('updateUserInfo')
      }
    },
    isSetPassportCode(val) {
      if (val == false) {
        this.$emit('updateUserInfo')
      }
    },
  },
  methods: {
    showSetMobileDialog() {
      this.isSetMobile = true
    },
    showSetPassportDialog() {
      this.isSetPassportCode = true
    },
  },
}
</script>

<style scoped lang="scss">
.datum-cont {
  padding: 24px 16px 16px 36px;
}

.identity-img {
  img {
    width: 400px;
    height: 224px;
    margin-bottom: 10px;
  }

  .img-last {
    margin-right: 10px;
  }
}
</style>
