<template>
  <el-dialog
    :title="$t('my_setMobile_1')"
    :visible.sync="showMask"
    width="500px"
  >
    <div class="setPassword">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('my_setMobile_2')" prop="mobile">
          <el-input
            type="text"
            v-model="ruleForm.mobile"
            :placeholder="$t('my_setMobile_3')"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="showMask = false">
        {{ $t('my_setMobile_4') }}
      </el-button>
      <el-button
        :loading="nextLoading"
        size="small"
        type="primary"
        @click="savePass"
      >
        {{ $t('my_setMobile_5') }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'setMobileDialog',
  props: {
    mobile: {
      type: String,
    },
    isshow: {
      type: Boolean,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
    mobile(val) {
      this.ruleForm.mobile = val
    },
  },
  data() {
    let that = this
    let valimobile = (rule, value, callback) => {
      let reg = /^\d{1,}$/
      if (value === '') {
        callback(new Error(that.$t('reg_mainLandUser_9')))
      } else if (!reg.test(value)) {
        callback(new Error(that.$t('请输入正确手机号')))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        mobile: '',
      },
      rules: {
        mobile: [{ validator: valimobile, trigger: 'blur' }],
      },
      showMask: false,
      nextLoading: false,
    }
  },
  mounted() {},
  methods: {
    savePass() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.nextLoading = true
          let res = await this.$axios.post('/api/User/updPhone', this.ruleForm)
          this.nextLoading = false
          if (res.code === 1) {
            this.showMask = false
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
