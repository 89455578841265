<template>
  <div class="wallet user-box">
    <div class="user-tit">
      <div class="ls"></div>
      <div class="text">{{ $t('my_walletAddr_1') }}</div>
    </div>
    <div class="wallet-cont">
      <el-button
        size="small"
        class="solt-button"
        plain
        @click="showAddWalletAddrDialog"
      >
        {{ $t('my_walletAddr_2') }}
      </el-button>
      <el-table class="wallet-table" :data="walletList" border size="small">
        <el-table-column
          prop="currency_type"
          :label="$t('my_walletAddr_3')"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="chain_addr"
          :label="$t('my_walletAddr_4')"
        ></el-table-column>
        <el-table-column prop="agreement" :label="$t('协议')"></el-table-column>
        <el-table-column :label="$t('是否默认')">
          <template #default="scope">
            <el-tag v-if="scope.row.is_default == 1">是</el-tag>
            <el-tag type="info" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          width="180"
          :label="$t('my_walletAddr_5')"
        >
          <template slot-scope="scope">
            <el-button
              class="table-but ls"
              @click="handleEditWallet(scope.row)"
              type="text"
              size="small"
            >
              <i class="table-icon el-icon-edit"></i>
            </el-button>
            <el-button
              class="table-but"
              type="text"
              size="small"
              @click="handleDeleteWallet(scope.row)"
            >
              <i class="table-icon el-icon-delete"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <add-wallet-addr-dialog
      ref="addWalletAddrDialog"
      :isshow.sync="isAddWalletAddrDialog"
      :currencyOptions="currencyOptions"
      @updateWalletAddrList="initData"
    ></add-wallet-addr-dialog>
  </div>
</template>

<script>
import addWalletAddrDialog from './user/addWalletAddrDialog.vue'

export default {
  components: {
    addWalletAddrDialog,
  },
  data() {
    return {
      currencyOptions: [],
      walletList: [],
      isAddWalletAddrDialog: false,
      list: '',
      tableData: [
        {
          date: '1',
          name: '1',
        },
      ],
    }
  },
  created() {
    this.initData()
  },
  methods: {
    async initData() {
      let res = await this.$axios.post('/api/Info/walletAddress')
      if (res.code === 1) {
        this.walletList = res.data
      }
      let currencyRes = await this.$axios.post('/api/Common/getCurrency')
      if (currencyRes.code === 1) {
        this.currencyOptions = currencyRes.data
      }
    },
    progressFormat() {
      let parst = this.$t('my_walletAddr_6') + '：$ 25000'
      return parst
    },
    showAddWalletAddrDialog() {
      this.isAddWalletAddrDialog = true
      this.$refs.addWalletAddrDialog.initData()
    },
    handleDeleteWallet(row) {
      let _that = this
      this.$confirm(_that.$t('my_walletAddr_7')).then(() => {
        this.deleteWallet(row)
      })
    },
    handleEditWallet(row) {
      this.isAddWalletAddrDialog = true
      this.$refs.addWalletAddrDialog.initData(JSON.parse(JSON.stringify(row)))
    },
    async deleteWallet(row) {
      let res = await this.$axios.post('/api/Info/delWallet', { id: row.id })
      if (res.code === 1) {
        this.initData()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.wallet {
  min-height: 300px;
}

.wallet-cont {
  padding: 10px 0 0px 10px;
  text-align: right;
}
.wallet-table {
  margin-top: 10px;
}

.table-but {
  padding: 2px 0;

  &.ls {
    margin-right: 16px;
  }
}

.table-icon {
  font-size: 18px;
}
</style>
